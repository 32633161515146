import { randomize, swap } from "../game.actions";

export function tilesReducer(tiles, action) {

    switch (action.type) {

        case 'initialize':
            let row=0;
            const initializedTiles = action.blankTiles.map((_, i) => {
                if (i > 0 && i % action.rows === 0) row++;
                const col = i % action.rows;

                return {
                    originalIndex: i,
                    currentIndex: i,
                    id: Math.floor(Math.random() * 10002),
                    width: `${action.tileSize}px`,
                    height: `${action.tileSize}px`,
                    cursor: "pointer",
                    zIndex: 1,
                    top: `${action.tileSize * row}px`,
                    left: `${action.tileSize * (i % action.rows)}px`,
                    backgroundImage: `url(${action.image})`,
                    backgroundSize: `auto ${action.gameboard.current.offsetWidth}px`,
                    backgroundPositionY: `-${action.tileSize * row}px`,
                    backgroundPositionX: `-${action.tileSize * (i % action.rows)}px`,
                    x: Math.floor(i / action.rows),
                    y: i % action.rows
                }
            });

            action.setEmpty(initializedTiles.at(-1));
            return initializedTiles;
            break;

        case 'tile_swap':
            let {swappedTiles, swappedEmpty} = swap(tiles, action.tile, action.emptyTile);
            if(action.setEmpty) action.setEmpty(swappedEmpty);
            return swappedTiles;
            break;

        case 'randomize':
            let {randomizedTiles, empty} = randomize(tiles, action.neibours, tiles.at(-1), action.times);
            action.setEmpty(empty);
            return randomizedTiles;
            break;

        case 'reposition_tiles':
            action.movingTiles.forEach((mt, i) => {
                let index = action.movingTilesCoods[i].index;
                tiles[index] = {
                    ...tiles[index],
                    top: `${action.movingTilesCoods[i].initialY}px`,
                    left: `${action.movingTilesCoods[i].initialX}px`
                }
            });
            return tiles;
            break;

        case 'drag_tiles':

            const moving = {...action.movingTile};

            const indexOfMoving = tiles.findIndex(t => t.id === moving.id);
            if (!action.neibours.current[action.emptyTile.originalIndex].includes(indexOfMoving)) return tiles;

            const emptyTilePos = {
                x: Number(action.emptyTile.left.replace(/[a-z]/gi, '')),
                y: Number(action.emptyTile.top.replace(/[a-z]/gi, ''))
            }
            const tileLeft = Number(moving.left.replace(/[a-z]/gi, ''));
            const tileTop = Number(moving.top.replace(/[a-z]/gi, ''));

            const isHMovement = action.emptyTile.top === moving.top;
            const isVMovement = action.emptyTile.left === moving.left;
            const xConstraint = [emptyTilePos.x, tileLeft].sort((a, b) => a - b);
            const yConstraint = [emptyTilePos.y, tileTop].sort((a, b) => a - b);

            const xDiff = action.clientX - action.initPos.mX;
            const yDiff = action.clientY - action.initPos.mY;
            let newX = action.initPos.tX, newY = action.initPos.tY;
            let updatedMovingTilesCoods = action.movingTilesCoods || [];

            if (isHMovement) {
                if (xDiff >= 0) {
                    const xHolder = newX + Math.abs(xDiff);
                    if (xHolder > xConstraint[0] && xHolder < xConstraint[1]) {
                        newX = xHolder;
                        updatedMovingTilesCoods = [...updatedMovingTilesCoods].map(coods => {
                            return {
                                ...coods,
                                currentX: coods.initialX + Math.abs(xDiff)
                            }
                        });
                    }
                }
                else {
                    const xHolder = newX - Math.abs(xDiff);
                    if (xHolder > xConstraint[0] && xHolder < xConstraint[1]) {
                        newX = xHolder;
                        updatedMovingTilesCoods = [...updatedMovingTilesCoods].map(coods => {
                            return {
                                ...coods,
                                currentX: coods.initialX - Math.abs(xDiff)
                            }
                        });
                    }
                }
            }

            if (isVMovement) {
                if (yDiff >= 0) {
                    const yHolder = newY + Math.abs(yDiff);
                    if (yHolder > yConstraint[0] && yHolder < yConstraint[1]) {
                        newY = yHolder;
                        updatedMovingTilesCoods = [...updatedMovingTilesCoods].map(coods => {
                            return {
                                ...coods,
                                currentY: coods.initialY + Math.abs(yDiff)
                            }
                        });
                    }
                }
                else {
                    const yHolder = newY - Math.abs(yDiff);
                    if (yHolder > yConstraint[0] && yHolder < yConstraint[1]) {
                        newY = yHolder;
                        updatedMovingTilesCoods = [...updatedMovingTilesCoods].map(coods => {
                            return {
                                ...coods,
                                currentY: coods.initialY - Math.abs(yDiff)
                            }
                        });
                    }
                }
            }


            if (action.movingTiles) {
                action.movingTiles.forEach((mt, i) => {
                    let index = updatedMovingTilesCoods[i].index;
                    tiles[index] = {
                        ...tiles[index],
                        top: `${updatedMovingTilesCoods[i].currentY}px`,
                        left: `${updatedMovingTilesCoods[i].currentX}px`
                    }
                });
            }

            tiles[indexOfMoving] = { ...moving, left: `${newX}px`, top: `${newY}px` }
            return [...tiles];

            break;
        
    }

}