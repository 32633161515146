import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./layout.css";
import { NavLink, useSearchParams } from "react-router-dom";
import { faCoffee, faCompass, faHomeLg, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';

export function Layout({ children }) {

    const [searchParams, setSearchParams] = useSearchParams();

    return <>{!searchParams.get('embed') && searchParams.get('embed') != "true" ? <div id="picpazz-layout">
        {/*  <header>
        </header> */}

        <section>

            <nav>

                <img src="/assets/images/logo.png" width="100%" />

                <ul>
                    <li><NavLink to="/"><FontAwesomeIcon icon={faHomeLg} size="3x" /> <br /> <span>Home</span></NavLink></li>
                    <li><NavLink to="/"><FontAwesomeIcon icon={faCompass} size="3x" /> <br /> <span>Explore Puzzles</span></NavLink></li>
                    <li><NavLink to="/"><FontAwesomeIcon icon={faPuzzlePiece} size="3x" /> <br /> <span>My Puzzles</span></NavLink></li>
                </ul>
            </nav>

            <main>
                {children}
            </main>
        </section>

        {/* <footer>
            footer
        </footer> */}

    </div> : children}</>
}