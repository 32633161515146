import { GameBoard } from "../../components/game-board/GameBoard";

export default function Embeded(){

    return <>
        <GameBoard
                rows={4}
                image={"/assets/images/avengers.jpg"}
                // onMove={setMoves}
                // showMoves={false}
                // showTimer={false}
                // onTick={handleTick}
            />
    </>

}