export function solved(tiles) {
    for (let i = 0; i < tiles.length; i++) {
        if (i !== tiles[i].currentIndex) return false;
    }
    return true;
}

export function swap(tiles, tile, emptyTile) {

    tiles[emptyTile.originalIndex] = {
        ...tiles[emptyTile.originalIndex],
        left: emptyTile.left,
        top: emptyTile.top,
        currentIndex: tile.currentIndex,
        backgroundImage: tile.backgroundImage,
        backgroundSize: tile.backgroundSize,
        backgroundPositionY: tile.backgroundPositionY,
        backgroundPositionX: tile.backgroundPositionX,
        x: tile.x,
        y: tile.y
    }

    tiles[tile.originalIndex] = {
        ...tiles[tile.originalIndex],
        currentIndex: emptyTile.currentIndex,
        left: tile.left,
        top: tile.top,
        x: emptyTile.x,
        y: emptyTile.y
    }

    return {
        swappedTiles: tiles,
        swappedEmpty: {
            ...tiles[tile.originalIndex],
            currentIndex: emptyTile.currentIndex
        }
    }

}

export function randomize(tiles, neibours, emptyTile, times) {

    let empty = { ...emptyTile }
    let tls = [...tiles];

    const randomizeFunc = () => {
        const theMovables = neibours.current[empty.originalIndex];
        const indexOfMoving = theMovables[Math.floor(Math.random() * theMovables.length)];
        const moving = tiles[indexOfMoving];
        let { swappedTiles, swappedEmpty } = swap(tls, moving, empty);
        tiles = swappedTiles;
        empty = swappedEmpty;
    }

    let count = times;
    while (count > 0) {
        randomizeFunc();
        count--;
    }

    return { randomizedTiles: tiles, empty };

}

export function emptyTileNeighbors(tiles, rows) {
    let row = 0;

    return tiles.reduce((a, _, i) => {
        if (i > 0 && i % rows === 0) row++;
        const col = i % rows;
        const tilesInSameColumn = Object.keys(tiles).filter((j) => j % rows === col);
        const tilesInSameRow = Object.keys(tiles).filter((k) => {
            const rowOfK = k === 0 ? 0 : Math.floor(k / rows);
            return row === rowOfK;
        });

        a[i] = tilesInSameColumn.reduce((b, l) => {
            return parseInt(l) - rows === parseInt(i) || parseInt(l) + rows === parseInt(i) ? [...b, parseInt(l)] : b;
        }, []).concat(tilesInSameRow.reduce((c, m) => {
            return parseInt(m) - 1 === parseInt(i) || parseInt(m) + 1 === parseInt(i) ? [...c, parseInt(m)] : c;
        }, []));

        return a;
    }, {});
}

export function getMaxMoves(tiles, neibours, emptyTile) {

    /* const exploreMove = (unExploredTiles, empty, isSolved = false) => {
        const tileIndexs = neibours[empty.originalIndex];
        const { swappedTiles, swappedEmpty } = swap(unExploredTiles, unExploredTiles[tileIndexs[i]], empty);
        const swappedSolution = solved(swappedTiles);
        if(swappedSolution){
            return true;
        }
        
        if (!isSolved) {
            for (let i = 0; i < tileIndexs.length; i++) {
                const { swappedTiles, swappedEmpty } = swap(unExploredTiles, unExploredTiles[tileIndexs[i]], empty);
                const swappedSolution = solved(swappedTiles);
                if(swappedSolution){
                    return exploreMove(swappedTiles, swappedEmpty, swappedSolution);;
                }
                else{
                    exploreMove(swappedTiles, swappedEmpty, swappedSolution);
                }
            }
        }
        return isSolved;
    } */

    // exploreMove(tiles, emptyTile);
}