import "./game.css";
import { GameBoard } from "../../components/game-board/GameBoard";
import { useRef, useState } from "react";

export function Game() {

    const [moves, setMoves] = useState(0);
    const [timer, setTimer] = useState('00:00:00');
    const image = useRef("/assets/images/avengers.jpg")

    function handleTick(t) {
        setTimer(`${t.hours.toString().padStart(2, '0')}:${t.minutes.toString().padStart(2, '0')}:${t.seconds.toString().padStart(2, '0')}`);
    }

    return <div id="game" style={{background:`red, url(${image.current})`, backgroundSize: "cover"}}>
        <div className="play-stats">
            <div>
                <h1>{moves}</h1>
                <span>moves</span>
            </div>
            <div>
                <h1>{timer}</h1>
                <span>Timer</span>
            </div>
        </div>
        <div>
            <GameBoard
                rows={4}
                image={image.current}
                onMove={setMoves}
                showMoves={false}
                showTimer={false}
                onTick={handleTick}
            />

            {/* <div>testing</div> */}
        </div>
    </div>
}