// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Game } from './pages/game/Game';
import './App.css';
import { Layout } from './components/layout/Layout';
import Embeded from './pages/embeded/Embeded';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='/' element={<Game />} />
          <Route path='/embeded' element={<Embeded />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
