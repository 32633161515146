import { useEffect, useReducer, useRef, useState } from "react";
import "./game-board.css";
import { tilesReducer } from "./reducers/tiles.reducer";
import { emptyTileNeighbors, getMaxMoves, solved } from "./game.actions";

export function GameBoard({ rows, image, maxMoves = 0, maxTime, onSolved, onFail, onMove, onTick, showMoves = true, showTimer = true }) {

    /** Actions
     * tiles:- initialize, swap, randomize, drag
     * movingTiles: drag
     * emptyTile: swap
     *
     * */
    const gameboard = useRef(null);
    const [tiles, tilesDispatch] = useReducer(tilesReducer, []);
    const neibours = useRef();
    const totalTiles = useRef(rows ** 2).current;
    const [isRandomized, setIsRandomized] = useState(false);

    const [canMove, setCanMove] = useState(false);
    const [movingTile, setMovingTile] = useState();
    const [movingTiles, setMovingTiles] = useState();
    const [emptyTile, setEmptyTile] = useState();
    const [initPos, setInitPos] = useState();
    const [movingTilesCoods, setMovingTilesCoods] = useState();
    const [movesCount, setMovesCount] = useState(maxMoves ? maxMoves : 0);
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [timer, setTimer] = useState();
    const [isDone, setIsDone] = useState(false);
    const [isSolved, setIsSolved] = useState(false);

    useEffect(() => {
        if (tiles.length == 0) {
            initialize();
        }
        else if (!isRandomized) {
            tilesDispatch({
                type: 'randomize',
                neibours,
                times: 100 * rows,
                setEmpty: empty => {
                    setEmptyTile(empty);
                    setIsRandomized(true);
                }
            });
        }

        if (movingTile && !canMove) {
            if (movingTiles && movingTiles.length >= 0) {
                const mvtls = movingTiles;
                const mt = mvtls.splice(0, 1).at(0);
                tilesDispatch({
                    type: 'tile_swap',
                    tile: mt,
                    emptyTile,
                    setEmpty: empty => {
                        setEmptyTile({
                            ...empty,
                            currentIndex: emptyTile.currentIndex
                        });
                    }
                });
                setMovingTiles(mvtls.length > 0 ? [...mvtls] : null);
                setMovingTile(mt);
            }
            else {
                setMovingTile(null);
            }
        }

        const isSolved = solved(tiles);
        setIsSolved(isSolved);
        setIsDone(isSolved);

    }, [tiles, movingTiles, movingTile, canMove]);

    /* // timer useEffect */
    useEffect(() => {
        let timerInterval = setInterval(() => {
            if (startTime && !isDone) {
                const now = new Date();
                let timeDifference = !endTime ? now.getTime() - startTime.getTime() : endTime.getTime() - now.getTime();

                const hours = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor(((timeDifference % (1000 * 60 * 60)) % (1000 * 60)) / 1000);

                if (endTime && timeDifference <= 0) {
                    setIsDone(true);
                }
                else {
                    if (onTick) {
                        onTick({ hours, minutes, seconds });
                    }
                    setTimer({ hours, minutes, seconds });
                }
            }
        }, 1000);

        return function () {
            clearInterval(timerInterval);
            timerInterval = null;
        }
    }, [startTime, endTime, isDone]);
    /* // end timer useEffect */

    /* is complete useEffect */
    useEffect(() => {

        if (maxMoves && movesCount < 1) {
            setIsDone(true);
        }

        if (isDone && isSolved && onSolved) {
            onSolved({
                moves: movesCount,
                timeTaken: timer
            });
        }

        if (isDone && !isSolved && onFail) {
            onFail({
                moves: movesCount,
                timeTaken: timer
            });
        }

    }, [movesCount, isDone, isSolved]);
    /* end is complete useEffect */

    useEffect(()=>{
        if(isRandomized){
            getMaxMoves(tiles, neibours.current, emptyTile);
        }
    }, [isRandomized]);

    function initialize() {

        const gameBoardStyle = gameboard.current.currentStyle || window.getComputedStyle(gameboard.current);
        let gameboardWidth = gameboard.current.offsetWidth - (parseFloat(gameBoardStyle.marginLeft) + parseFloat(gameBoardStyle.marginRight) + parseFloat(gameBoardStyle.borderLeftWidth) + parseFloat(gameBoardStyle.borderRightWidth));

        const tileSize = gameboardWidth / rows;
        gameboard.current.style.height = gameboardWidth + 'px';

        const emptyTiles = new Array(totalTiles).fill(0);
        neibours.current = emptyTileNeighbors(emptyTiles, rows);
        tilesDispatch({
            type: 'initialize',
            blankTiles: emptyTiles,
            rows,
            tileSize,
            gameboard,
            image,
            setEmpty: empty => {
                setEmptyTile(empty);
            }
        });

    }

    function handleMouseDown(tile, evt = null) {

        if (!isRandomized || isDone || isEmptyTile(tile)) return;

        if (!movingTile) {

            if (neibours.current[emptyTile.originalIndex].includes(tile.originalIndex)) {
                setMovingTile(tile);
                setCanMove(neibours.current[emptyTile.originalIndex].includes(tile.originalIndex));
                setMovingTiles(null);

                if (evt) {
                    setInitPos({
                        mX: evt.clientX,
                        mY: evt.clientY,
                        tX: Number(tile.left.replace(/[a-z]/gi, '')),
                        tY: Number(tile.top.replace(/[a-z]/gi, ''))
                    });
                }
            }
            else if (rows > 3) {
                let mvt;
                if (Math.floor(tile.originalIndex / rows) === Math.floor(emptyTile.originalIndex / rows)) {
                    // console.log("move all row");
                    mvt = tiles.reduce((a, b) => Math.floor(b.originalIndex / rows) === Math.floor(emptyTile.originalIndex / rows) ? [...a, b] : a, []);
                }
                else if (tile.originalIndex % rows === emptyTile.originalIndex % rows) {
                    // console.log("move all column");
                    mvt = tiles.reduce((a, b) => b.originalIndex % rows === emptyTile.originalIndex % rows ? [...a, b] : a, []);
                }

                if (mvt) {

                    let indexOfEmpty = mvt.findIndex(t => t.originalIndex === emptyTile.originalIndex);
                    let indexOfTile = mvt.findIndex(t => t.originalIndex === tile.originalIndex);

                    if (indexOfTile < indexOfEmpty) {
                        mvt = mvt.slice(indexOfTile, indexOfEmpty).reduce((a, b) => [b, ...a], []);
                    }
                    else {
                        // console.log("up")
                        mvt = mvt.slice(indexOfEmpty + 1, indexOfTile + 1);
                    }
                    // console.log([...mvt]);
                    let mt = mvt.at(0);

                    // console.log(mt);
                    setMovingTile(mt);
                    setCanMove(neibours.current[emptyTile.originalIndex].includes(mt.originalIndex));
                    setMovingTiles(mvt);
                    if (evt) {
                        setInitPos({
                            mX: evt.clientX,
                            mY: evt.clientY,
                            tX: Number(mt.left.replace(/[a-z]/gi, '')),
                            tY: Number(mt.top.replace(/[a-z]/gi, ''))
                        });
                    }

                    setMovingTilesCoods(mvt.map(t => {
                        return {
                            index: tiles.findIndex(tl => tl.id === t.id),
                            initialX: parseInt(t.left),
                            initialY: parseInt(t.top),

                            currentX: parseInt(t.left),
                            currentY: parseInt(t.top),
                        }
                    }));
                }
            }
        }
    }

    function handleMouseUp() {
        if (canMove) {
            setCanMove(false);

            if (movingTiles) {
                tilesDispatch({
                    type: 'reposition_tiles',
                    movingTiles,
                    movingTilesCoods
                });
            }

            tilesDispatch({
                type: 'tile_swap',
                tile: movingTile,
                emptyTile,
                setEmpty: empty => {
                    if (!movingTiles)
                        setEmptyTile({ ...empty, currentIndex: emptyTile.currentIndex })
                }
            });

            setInitPos(null);
            setMovesCount(movesCount => maxMoves ? movesCount - 1 : movesCount + 1);
            if (onMove) {
                onMove(movesCount + 1);
            }

            if (!startTime) {
                let sTime = new Date();
                if (maxTime) {
                    let eTime = new Date(sTime);
                    if (maxTime.includes('h')) {
                        setEndTime(new Date(eTime.setHours(eTime.getHours() + parseInt(maxTime))));
                    }
                    else if (maxTime.includes('m')) {
                        setEndTime(new Date(eTime.setMinutes(eTime.getMinutes() + parseInt(maxTime))));
                    }
                    else if (maxTime.includes('s')) {
                        setEndTime(new Date(eTime.setSeconds(eTime.getSeconds() + parseInt(maxTime))));
                    }
                }
                setStartTime(sTime);
                setTimer({ hours: 0, minutes: 0, seconds: 0 });
            }
        }
    }

    function handleMouseMove(evt) {

        if (canMove && movingTile && initPos) {
            console.log("moving");
            tilesDispatch({
                type: 'drag_tiles',
                neibours,
                movingTile,
                emptyTile,
                initPos,
                movingTilesCoods,
                movingTiles,
                clientX: evt.clientX,
                clientY: evt.clientY
            });

        }
    }

    function isEmptyTile(tile) {
        return tile.originalIndex === emptyTile.originalIndex;
    }

    function isMovableTile(tile) {
        return neibours.current[emptyTile.originalIndex].includes(tile.originalIndex);
    }

    return <div className="game-container">
        {(showMoves || showTimer) && <div className="game-stats" style={gameboard.current ? { width: `${gameboard.current.offsetWidth}px` } : {}}>
            {timer && <>
                {showMoves && <div className="moves">
                    <strong>{movesCount}</strong><div><small>Moves</small></div>
                </div>}
                {showTimer && <div className="timer">
                    <strong>
                        {timer.hours.toString().padStart(2, '0')}:
                        {timer.minutes.toString().padStart(2, '0')}:
                        {timer.seconds.toString().padStart(2, '0')}
                    </strong>
                    <div><small>Timer</small></div>
                </div>}
            </>}
        </div>}

        <div className="game-board" onTouchMoveCapture={(e) => handleMouseMove(e)} onMouseMove={(e) => handleMouseMove(e)} ref={gameboard}>
            {emptyTile && tiles.length > 0 && tiles.map((tile, i) => <div
                key={tile.id}
                className={`tile ${isMovableTile(tile) ? 'movable-tiles' : (isEmptyTile(tile) ? 'empty-tile' : '')}`}
                style={{
                    ...tile,
                    ...(isEmptyTile(tile) ? { backgroundImage: 'none' } : {})
                }}
                onMouseDown={(e) => handleMouseDown(tile, e)}
                onMouseUp={() => handleMouseUp()}
            >
                <div>
                    {/* <h1>{isEmptyTile(tile) ? '' : tiles[tile.originalIndex].currentIndex + 1}</h1> */}
                </div>
            </div>)
            }
        </div>

        {isRandomized && isSolved && <h1>Solved</h1>}
        {isRandomized && !isSolved && isDone && <h1>Game Over</h1>}

    </div>

}